#viewer {
    display: flex;

    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;

    .content {
        width: 80vw;
        height: 60vh;

        img {
            width: 100%;
            height: 100%;

            object-fit: contain;

            image-rendering: pixelated;
        }

        @media screen and (min-width: 568px) {
            width: 60vw;
        }
    }

    figcaption {
        text-align: center;
    }

    nav {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;

        ul {
            display: flex;

            justify-content: center;

            list-style: none;

            padding: $padding / 2;
            margin: 0;
        }

        li {
            margin: 0 #{$padding / 2} $padding / 2;

            text-align: right;
        }

        button,
        a {
            padding: 0;
            margin: 0;

            border: 0;

            cursor: pointer;

            background: none;

            outline: 0;

            text-decoration: none;
            color: black;

            &:hover {
                color: red;
            }
        }

        #random-btn {
            border-bottom: 2px solid red;
        }

        @media screen and (min-width: 568px) {
            width: auto;

            left: auto;
            top: 50%;
            right: $padding;

            transform: translate3d(0, -50%, 0);

            ul {
                display: block;
            }

            li {
                margin: 0 0 $padding / 2;
            }
        }
    }
}